import React, { useEffect, useMemo ,useState} from 'react';
import { isEmpty } from 'lodash';
import '../css/femmar.css';
import { Form, FormRenderProps } from 'react-final-form';
import { toast } from 'react-toastify';
import TopComp from '../components/TopComp';
import FooterCopyright from '../components/FooterCopyright';
import myContent from '../jsonContent/trans_HistoryQuestionnaire.json';
import { selectLanguageIndex } from '../redux/slices/auth';
import { useAppDispatch, useAppSelector } from '../redux/store';

import FormTemplate from '../components/template/FormTemplate';
import CustomForm from '../components/Organisms/CustomForm/CustomForm';
import { QuizTypes } from '../types/models/QuizTypes';
import { formatValuesToForm, formatValuesToSave } from '../utils/result.utils';
import { selectWeekSymptomAnswers } from '../redux/slices/visitedscreens';
import { getQuestionFieldName } from '../utils/question.utils';
import { QuestionTypes } from '../types/models/QuestionTypes';
import { getQuizByTypeAction } from '../redux/actions/quiz';
import PageTitleComp from '../components/PageTitleComp';
import { saveAnswersAction } from '../redux/actions/questionAction';
import {
  HeightUnits,
  HeightValue,
  WeightUnits,
  WeightValue,
} from '../types/types';
import WelcomeFooterBtns from '../components/WelcomeFooterBtn';
import { IQuestion } from '../types/models/Quiz';
import TickComp from '../components/TickComp';
import CustomFormTwo from '../components/Organisms/CustomForm/CustomFormTwo';


interface GroupedQuestions {
  title: string;
  questions: IQuestion[];
}
interface FormValues {
  [key: string]: any;
}

const HistoryQuestionnaire = () => {
  const [isDateError, setIsDateError] = useState(false);

  const langNum = useAppSelector(selectLanguageIndex);
  const { pageTitle } = myContent.trans[langNum];

  const dispatch = useAppDispatch();
  const answers = useAppSelector(selectWeekSymptomAnswers);
  const quiz = useAppSelector((state) => state.quiz);
  const { loading, currentQuiz } = quiz;

  const [dateInput, setDateInput] = useState('');
  

  // const questions = useMemo(() => {
  //   if (!currentQuiz?.questions.length) {
  //     return [];
  //   }
  //   const groupedQuestions = currentQuiz.questions.reduce<Record<string, IQuestion[]>>((acc, question) => {
  //     const constantName = question.constantName || 'Other';
  //     if (!acc[constantName]) {
  //       acc[constantName] = [];
  //     }
  //     acc[constantName].push(question);
  //     return acc;
  //   }, {});

  //   const constantNameOrder = [
  //     'About You',
  //     'Medical History',
  //     'Mental Health',
  //     'Nutrition and Lifestyle'
      
  //   ];
  //   const sortedQuestions = constantNameOrder.reduce<IQuestion[]>((acc, constantName) => {
  //     if (groupedQuestions[constantName]) {
  //       const sortedGroup = [...groupedQuestions[constantName]].sort(
  //         (a, b) => a.order - b.order
  //       );
  //       acc.push(...sortedGroup);
  //     }
  //     return acc;
  //   }, []);

    // Add any remaining questions that weren't in the constantNameOrder
    // Object.entries(groupedQuestions).forEach(([constantName, questions]) => {
    //   if (!constantNameOrder.includes(constantName)) {
    //     const sortedGroup = [...questions].sort((a, b) => a.order - b.order);
    //     sortedQuestions.push(...sortedGroup);
    //   }
    // });

    // return sortedQuestions;

    // return currentQuiz.questions
    // .map((question, index) =>
    //   index < 8 ? { ...question, optional: true } : question,
    // );
  // }, [currentQuiz?.questions]);

  const groupedQuestions = useMemo(() => {
    if (!currentQuiz?.questions.length) {
      return [];
    }
    const questionGroups = currentQuiz.questions.reduce<Record<string, IQuestion[]>>((acc, question) => {
      const constantName = question.constantName || 'Other';
      if (!acc[constantName]) {
        acc[constantName] = [];
      }
      acc[constantName].push(question);
      return acc;
    }, {});

    const constantNameOrder = [
      'About You',
      'Medical History',
      'Mental Health',
      'Nutrition and Lifestyle'
    ];

    return constantNameOrder.reduce<GroupedQuestions[]>((acc, constantName) => {
      if (questionGroups[constantName]) {
        const sortedQuestions = [...questionGroups[constantName]].sort(
          (a, b) => a.order - b.order
        );
        acc.push({
          title: constantName,
          questions: sortedQuestions
        });
      }
      return acc;
    }, []);
  }, [currentQuiz?.questions]);
  

  const initialValues = useMemo(() => {
    if (!currentQuiz) {
      return undefined;
    }
    let values = {};
    if (answers.length) {
      values = formatValuesToForm(answers, currentQuiz.questions);
    }
    if (isEmpty(values)) {
      values = currentQuiz.questions.reduce<{ [key: string]: any }>(
        (partial, question) => {
          const fieldName = getQuestionFieldName(question.id);
          if (question.type === QuestionTypes.MULTIPLE_CHOICE) {
            partial[fieldName] = [];
          } else if (question.type === QuestionTypes.NESTED_MULTIPLE_CHOICE) {
            partial[fieldName] = [];
          } else if (question.type === QuestionTypes.HEIGHT) {
            partial[fieldName] = { unit: HeightUnits.CM } as HeightValue;
          } else if (question.type === QuestionTypes.WEIGHT) {
            partial[fieldName] = { unit: WeightUnits.KG } as WeightValue;
          }
          return partial;
        },
        {},
      );
    }
    return values;
  }, [currentQuiz]);

  // const saveWeekAnswer = async (values: any) => {
  //   // Add the type to the payload
  //   const payload = {
  //     result: formatValuesToSave(values),
  //     type: 'HistoryQuestionnaire'
  //   };
  //   await dispatch(saveAnswersAction(payload));
  // };
  const saveWeekAnswer = async (values: any, type: string) => {
    // Add the type to the payload
    const payload = {
      result: formatValuesToSave(values),
      type
    };
    await dispatch(saveAnswersAction(payload));
  };

  const handleFormSubmit = (values: FormValues) => {
    const additionalQuestion = currentQuiz?.questions.find(
      (question) =>
        question.constantName === 'Medical History' &&
        question.order === 4
    );
  
    // Check for date error on form submission
    if (additionalQuestion) {
      const dateValue = dateInput;
  
      if (!dateValue || dateValue.trim() === '') {
        setIsDateError(true);
        toast.error('Please select the date of your last menstrual period');
        return; // Prevent submission if date is not filled
      }
      
      setIsDateError(false);
      
      // Add date to form values
      const questionFieldName = getQuestionFieldName(additionalQuestion.id);
      const updatedValues = {
        ...values,
        [questionFieldName]: dateValue
      };
      // console.log('nikhil', updatedValues)
      // Save answers with updated values including the date
      saveWeekAnswer(updatedValues, 'HistoryQuestionnaire');
      localStorage.setItem('isFirstTimeUser', JSON.stringify(false));
    }
  };
  

  useEffect(() => {
    dispatch(getQuizByTypeAction({ type: QuizTypes.HISTORY }));
  }, []);

  const renderForm = (formProps: FormRenderProps<Record<string, any>>) => {
    const { handleSubmit, form, values, valid ,touched={}, errors={}} = formProps;
    
    const additionalQuestion = currentQuiz?.questions.find(
      (question) => 
        question.constantName === 'Medical History' && 
        question.order === 4
    );
    const fieldName = additionalQuestion ? getQuestionFieldName(additionalQuestion.id) : '';
    const hasError = touched[fieldName] && errors[fieldName];
    return (
      <form onSubmit={handleSubmit} className="w-full">
        <div className="w-full">
        {additionalQuestion && (
        <div className="mb-8 mt-2 ">
          <div className="flex flex-col flex-item-center">
            <p className="mb-1 ms-2 ">
              <b>{additionalQuestion.text}</b>
            </p>
            <input
              type="date"
              id={`date-${additionalQuestion.id}`}
              name={getQuestionFieldName(additionalQuestion.id)} 
              className={`register-input width9030 lastmensturaldate  ${hasError ? 'border-red-500' : ''}`}
              required
              onChange={(e) => {
                const newValue = e.target.value;
                setDateInput(newValue);
                form.change(getQuestionFieldName(additionalQuestion.id), newValue);
                setIsDateError(false);
              }}
            />
            
            {additionalQuestion.options?.map(option => (
            <p key={option.id} className=" mt-1  text-dark">
              {option.text}
            </p>
            ))}
          {groupedQuestions.map((group) => (
            <div 
              key={`section-${group.title.toLowerCase().replace(/\s+/g, '-')}`} 
              className="mb-8"
            >
              <h3 className="text-center text-xl font-semibold my-3">{group.title}</h3>
              <CustomFormTwo
                {...formProps}
                questions={group.questions}
                showSubmitButton={false}
              />
            </div>
          ))}


            {/* {isDateError && (
                  <span className="text-danger mt-1">Please select the date of your last menstrual period</span>
                )} */}
          </div>
        </div>
      )}
        </div>
        <div className="flex flex-col flex-item-left width9060 mt-5">
          <TickComp 
          onMarkDone={() => {
            // Check date field before allowing form submission
            const dateValue = dateInput;
            if (!dateValue || dateValue.trim() === '') {
              setIsDateError(true);
              toast.error('Please select the date of your last menstrual period');
              return;
            }
            handleSubmit();
          }} 
          isValid={valid && !isDateError} />
        </div>
      </form>
    );
  };

  if (loading || !currentQuiz) {
    return <div>Loading...</div>;
  }

  // console.log(initialValues);

  return (
    <div className="padding-bottom5 minHeight100vh flex flex-col flex-item-center flex-space-between gap-half page-full ">
      <TopComp />
      <PageTitleComp pageTitle={pageTitle} />
      <div className="flex mx-auto flex-col flex-item-center flex-space-around ">
      <p className="padding1 flex-item-center text-left justify-content-center  item-center text-dark  width9060">Welcome to Femmar’s Natural Menopause Solution!  We’re delighted you’re taking the right steps to support your menopause and future health. <br /> We’ll be supporting you in every step of your journey. For the program to be most effective, we just need a little more information about your current  <br />diet,  lifestyle and health.  This also helps you to better understand your symptoms.  Every question provides important information and it won’t take you too long <br /> to complete. Let’s get started! Enjoy your journey to better health! </p>
      <Form 
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          render={renderForm}
        />     
      {/* <div className="w-full">
      {groupedQuestions.map((group, index) => (
            <div 
            key={`section-${group.title.toLowerCase().replace(/\s+/g, '-')}`} 
            className="mb-8">
              <h4 className="text-center text-xl font-semibold mb-4"><b>{group.title}</b></h4>
              <FormTemplate
          onSubmit={handleSubmit}
          Component={CustomForm}
          questions={group.questions}
          initialValues={initialValues}
        />
        </div>
          ))}
        </div> */}
        <br />
        <p className="padding1 item-start text-dark">Many thanks for completing the questionnaire and welcome to better health!
        </p>
        <WelcomeFooterBtns />
        <FooterCopyright />
      </div>
    </div>
  );
};

export default HistoryQuestionnaire;
