import React from 'react';
import '../css/femmar.css';

import { useNavigate } from 'react-router-dom';

import iconModulesMenu from '../images/icons/iconModules.png';
import iconShop from '../images/icons/iconShop.png';
import iconConsult from '../images/icons/iconConsult.png';

const Big3PinkBtnsComp = () => {
  const navigate = useNavigate();

  const handleConsult = () => {
    navigate('/Consult');
  };

  const handleModulesMenu = () => {
    navigate('/SixWeekMenu');
  };

  const handleShop = () => {
    window.open("https://www.femmar.com/recommended-partners", '_blank');
  };

  return (
    <div className="flex flex-col flex-item-center flex-space-between gap-half width100">
      <div className="flex flex-row flex-item-center flex-space-around gap1">
        <button
          type="button"
          onClick={handleModulesMenu}
          className="headerBtn-big">
          <div className="flex flex-col flex-item-center flex-space-around gap-half">
            <img src={iconModulesMenu} className="menu-icon" alt="" />
            <p className="font14px text-center">Modules Menu</p>
          </div>
        </button>

        <button type="button" onClick={handleShop} className="headerBtn-big">
          <div className="flex flex-col flex-item-center flex-space-around gap-half">
            <img src={iconShop} className="menu-icon" alt="" />
            <p className="font14px text-center">Shop</p>
          </div>
        </button>

        <button type="button" onClick={handleConsult} className="headerBtn-big">
          <div className="flex flex-col flex-item-center flex-space-around gap-half">
            <img src={iconConsult} className="menu-icon" alt="" />
            <p className="font14px text-center">Book a Consult</p>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Big3PinkBtnsComp;
