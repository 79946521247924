import React from 'react';
import { QuestionTypes } from '../../../types/models/QuestionTypes';
import SingleChoiceQuestion from '../SingleChoiceQuestion/SingleChoiceQuestion';
import MultipleChoiceQuestion from '../MultipleChoiceQuestion/MultipleChoiceQuestion';
import NestedChoiceQuestion from '../NestedChoiceQuestion/NestedChoiceQuestion';
import { IQuestion } from '../../../types/models/Quiz';
import HeightQuestion from '../HeightQuestion/HeightQuestion';
import WeightQuestion from '../WeightQuestion/WeightQuestion';

interface IquestionProps {
  question: IQuestion;
  correctValue?: number[];
}

const Question: React.FC<IquestionProps> = ({ question, correctValue }) => {
 
  switch (question.type) {
    case QuestionTypes.SINGLE_CHOICE:
      return (
        <SingleChoiceQuestion question={question} correctValue={correctValue} />
      );
    case QuestionTypes.MULTIPLE_CHOICE:
      return (
        <MultipleChoiceQuestion
          question={question}
          correctValue={correctValue}
        />
      );
    case QuestionTypes.NESTED_MULTIPLE_CHOICE:
      return <NestedChoiceQuestion question={question} />;
    case QuestionTypes.HEIGHT:
      return <HeightQuestion question={question} />;
    case QuestionTypes.WEIGHT:
      return <WeightQuestion question={question} />;
    default:
      return null;
  }
};

export default Question;
