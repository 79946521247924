import React from 'react';
import { FormRenderProps } from 'react-final-form';
import Question from '../../molecules/Question/Question';
import { IQuestion } from '../../../types/models/Quiz';
import { CorrectAnswer } from '../../../types/models/ApiResult';
import TickComp from '../../TickComp';

// Separate interface for the additional props we want to pass
interface CustomFormProps {
  questions: IQuestion[];
  correctValues?: CorrectAnswer[];
  showSubmitButton?: boolean;
}

// Combine FormRenderProps with our custom props
type ICustomForm = FormRenderProps<Record<string, any>> & CustomFormProps;

const CustomFormTwo: React.FC<ICustomForm> = ({
  questions,
  form,
  correctValues,
  showSubmitButton = false,
  handleSubmit,
  valid,
}) => {
  const renderQuestion = (question: IQuestion) => {
    // Sort the options by the 'order' field before passing them to the Question component
    const sortedOptions = question.options
      ? [...question.options].sort((a, b) => a.order - b.order)
      : [];

    const correctValue = correctValues?.find(
      ({ questionId }) => questionId === question.id,
    );

    return (
      <Question
        key={`form-field-${question.id}`}
        question={{ ...question, options: sortedOptions }}
        correctValue={correctValue?.answers}
      />
    );
  };

  return (
    <div className="flex flex-col flex-item-left width9060">
      {questions.map(renderQuestion)}
      {/* {showSubmitButton && (
        <div className="flex justify-center mt-4">
          <button
            type="submit"
            className="btn-primary"
            disabled={!valid}
          >
            Submit
          </button>
        </div>
      )} */}
    </div>
  );
};

export default CustomFormTwo;
