import React from 'react';
import { FormRenderProps, useFormState } from 'react-final-form';
import Question from '../../molecules/Question/Question';
import TickComp from '../../TickComp';
import { IQuestion } from '../../../types/models/Quiz';
import { CorrectAnswer } from '../../../types/models/ApiResult';

interface ICustomForm extends FormRenderProps<Record<string, any>> {
  questions: IQuestion[];
  correctValues?: CorrectAnswer[];
}

const CustomForm: React.FC<ICustomForm> = ({
  questions,
  form,
  correctValues,
}) => {
  const { invalid } = useFormState({ subscription: { invalid: true } });
  const renderQuestion = (question: IQuestion) => {
    const correctValue = correctValues?.find(
      ({ questionId }) => questionId === question.id,
    );
    return (
      <Question
        key={`form-field-${question.id}`}
        question={question}
        correctValue={correctValue?.answers}
      />
    );
  };

  return (
    <div className="flex flex-col flex-item-left width9060">
      {questions.map(renderQuestion)}
       <TickComp onMarkDone={form.submit} isValid={!invalid} /> 
    </div>
  );
};

export default CustomForm;
