import React from 'react';
import { INestedValue } from '../../../types/models/QuestionTypes';
import './NestedChoice.css';
import { IQuestion, IQuestionOptions } from '../../../types/models/Quiz';

interface INestedChoiceProps {
  value: INestedValue[];
  options: IQuestionOptions[];
  nestedQuestions: IQuestion['nestedQuestions'];
  onChange: (value: INestedValue) => void;
}

const NestedChoice: React.FC<INestedChoiceProps> = ({
  value = [],
  options,
  nestedQuestions,
  onChange,
}) => {
 

  return (
    <div className="flex flex-wrap nested-choice">
      {nestedQuestions.map((question) => (
        <div key={`${question.id}-${question.text}`} className="w-100">
          <div className="row  align-items-center ">
          <p className="h3 my-3 font600 col-lg-4 ">{question.text}</p>
          <div className="col-lg-8 ">
          <div className="d-flex flex-nowrap justify-content-center">

            {options.map((option) => {
              const existingValue = (Array.isArray(value) ? value : [])?.find(
                (subquestion) => subquestion.questionId === question.id,
              );
            
              return (
                <button
                  type="button"
                  onClick={() =>
                    onChange({ questionId: question.id, answer: option.id })
                  }
                  className={
                    existingValue?.answer === option.id ? 'selected' : ''
                  }
                  key={`${option.id}-${option.text}`}>
                  {option.text}
                </button>
              );
            })}
          </div>
          </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NestedChoice;
